<template>
    <div>
        <header-portrate />

    <div class="pa-2 m-2">
        
        <bread-crumbs :items="items"></bread-crumbs>
        <tabs-comp :tabs="tabs" />
        <v-simple-table style="margin-top:25px;">
            <thead>
                <tr>
                    <th class="text-center">الحساب</th>
                    <th class="text-center">الاسم العربي</th>
                    <th class="text-center">الاسم الانجليزي</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in paytypesrows" :key="index">
                    <td>{{item.classid}}</td>
                    <td>{{item.namear}}</td>
                    <td>{{item.nameen}}</td>
                </tr>
            </tbody>
        </v-simple-table>
    </div>

        <Footer />
    </div>
</template>

<script>
import breadCrumbs from '@/components/breadCrumbs.vue'
import TabsComp from '@/components/tabsComp.vue'
import axios from 'axios';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
export default {
    components: { breadCrumbs, TabsComp, HeaderPortrate, Footer },
    data() {
        return {
            paytypesrows: [
                
            ],
        }
    },
    created() {
        this.getDefiniation();
    },
    methods: {
        getDefiniation(){
            const post = new FormData();
            post.append("type","getDefination")
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            post.append("data[lang]",this.lang.langname);
            post.append("data[classid]",'all');
            axios.post(
                this.$store.state.SAMCOTEC.r_path,post
            ).then((response) => {
                // console.log(response.data);
                this.paytypesrows = response.data.results.data;
            })
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        tabs: function(){
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        },
        items: function() {
            return {
                    text: this.lang.definition,
                    disabled: true,
                    to: '/finance/definition',
                }
        },
       
    },
}
</script>